@import url("https://fonts.googleapis.com/css2?family=Cabin&display=swap");
$bluemuted: #acbbe4;
$prussianblue: #0d324d;
$greenyellow: #E1DD8F;
*{
    font-family: 'Cabin', sans-serif;
}

.intro-container{
    max-width: 100%;
    width: 100vw;
    height: 80vh;
    padding: 4rem;
    color: white;
}
.container{
    max-width: 100%;
    width: 100vw;
    height: auto;
    padding: 4rem;
    color: white;
    background-color: black;
}

#section-header{
    margin-top: 8rem;
}

#project-header{
    color: $greenyellow
}

#project-tech{
    color: $bluemuted
}
#quoteEnd{
    color: $bluemuted;
    font-size: '22px';
    font-style: 'italic';
    font-family: 'Cabin', sans-serif;;
}
#intro-description{
    text-align: center;
    color: white
}


#journey-subtitle{
    color: $bluemuted;
}

#journey-title{
    color: $greenyellow;
}

#journey-description{
    text-align: center;
}

#logo{
    color:$bluemuted;
    font-size: 25px;
    .fade{
        opacity: 0.4;
    }
}

#resume{
    font-size: 25px;
}

#intro{
    height: auto;
    margin-top: 5rem;
}

#intro-text{
    text-align: center;
    color: $bluemuted;
    font-size: 38px;
}

#intro-text-bold{
    text-align: center;
    color: $bluemuted;
    font-size:  68px;
    font-weight: 900;
}

#intro-img{
    border-radius:'50%';
    margin-top: '2rem';
}

#header-text-bold{
    text-align: center;
    color: white;
    font-size: 44px;
    font-weight: 900;
}


/*gallery */
#gallery-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 55px;
  }

  #thumbnail-gallery-container {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 10px 25px 10px 25px;
  }

  .thumbnail {
    width: 48%;
    margin-bottom: 2%; /* (100-32*3)/2 */
    position: relative;
    }

  img.thumbnail-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: top center;
    filter: grayscale(100%);        
   }

   img.thumbnail-image:hover {
     filter: grayscale(0%);

   }

@media only screen and (max-width: 800px){
  #gallery-section {
    padding: 10px 10px 0px 10px;
  }
  #thumbnail-gallery-container {
      padding: 0px;

  }
  
}